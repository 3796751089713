import { useSelector } from "react-redux";
import { selectTakes3D } from "../app/slices/TrainingSlice";
import { InteractionType } from "../models/interactions/Interaction";
import { IAttach } from "../models/interactions/Attach";
import { IShowUIProp } from "../models/interactions/ShowUIProp";

export default function useProjectSafety() {
  const allTakes = useSelector(selectTakes3D);

  const propHasReferencesInProject = (propInstanceId: string) => {
    return allTakes.some((take) => {
      // check if prop is spawned on the map
      const inProps = take.props.some(
        (prop) => prop.instanceId === propInstanceId
      );
      if (inProps) return true;

      // check if prop is in attaches OR show UI prop
      return take.interactions.some((i) => {
        if (i.type === InteractionType.Attach) {
          const converted = i as IAttach;
          return converted.propGuid === propInstanceId;
        } else if (i.type === InteractionType.ShowUIProp) {
          return (i as IShowUIProp).propGuid === propInstanceId;
        }
      });
    });
  };

  const actorHasReferencesInProject = (npcInstanceId: string) => {
    return allTakes.some((take) => {
      return take.actors.some((actor) => actor.actorId === npcInstanceId);
    });
  };

  return { propHasReferencesInProject, actorHasReferencesInProject };
}
