import { useSelector } from "react-redux";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";
import Viewport3DSetCameraLayer from "./Viewport3DSetCameraInteractionLayer";
import Viewport3DTakeCameraLayer from "./Viewport3DTakeCameraLayer";
import {
  selectSelectedElement,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import { useMemo } from "react";
import Interaction, {
  InteractionType,
} from "../../models/interactions/Interaction";

export default function Viewport3DCameraLayer(props: Viewport3DLayerProps) {
  const take = useSelector(selectSelectedTake3D);
  const interaction = useSelector(selectSelectedElement);

  const takeCamLayer = <Viewport3DTakeCameraLayer {...props} />;
  const interactionCamLayer = <Viewport3DSetCameraLayer {...props} />;

  const isSetCamSelected = useMemo(() => {
    if (!interaction) return false;

    if (
      (interaction as Interaction).type !== undefined &&
      (interaction as Interaction).type === InteractionType.SetCamera &&
      (interaction as Interaction).id !== take?.mainCamera.id
    )
      return true;
    return false;
  }, [interaction]);

  return (
    <>
      {!isSetCamSelected && takeCamLayer}
      {isSetCamSelected && interactionCamLayer}
    </>
  );
}
