import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";

export enum SelectedTab {
  Timeline = 0,
  ActorPresets = 1,
  PropPresets = 2,
}

interface GlobalState {
  isLoading: boolean;
  isPropertiesColumnOpen: boolean;
  isMenuExpanded: boolean;
  selectedTab: SelectedTab;
  showCameraWidget: boolean;
}

const initialState: GlobalState = {
  isLoading: false,
  isPropertiesColumnOpen: true,
  isMenuExpanded: true,
  selectedTab: SelectedTab.Timeline,
  showCameraWidget: true,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsPropertiesColumnOpen: (state, action: PayloadAction<boolean>) => {
      state.isPropertiesColumnOpen = action.payload;
    },
    setIsMenuExpanded: (state, action: PayloadAction<boolean>) => {
      state.isMenuExpanded = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<SelectedTab>) => {
      state.selectedTab = action.payload;
    },
    setShowCameraWidget: (state, action: PayloadAction<boolean>) => {
      state.showCameraWidget = action.payload;
    },
  },
});

// reducers
export const {
  setIsLoading,
  setIsPropertiesColumnOpen,
  setIsMenuExpanded,
  setSelectedTab,
  setShowCameraWidget,
} = globalSlice.actions;

export const selectIsLoading = (state: RootState) => state.global.isLoading;
export const selectIsPropertiesColumnOpen = (state: RootState) =>
  state.global.isPropertiesColumnOpen;
export const selectIsMenuExpanded = (state: RootState) =>
  state.global.isMenuExpanded;
export const selectSelectedTab = (state: RootState) => state.global.selectedTab;
export const selectShowCameraWidget = (state: RootState) =>
  state.global.showCameraWidget;

export default globalSlice.reducer;
