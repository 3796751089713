import { useState } from "react";
import PiAnimatedPopupWindowPreview from "../PiAnimatedPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { ISetCamera } from "../../../models/interactions/SetCamera";

import CameraPickerField from "./CameraPickerField";

export default function SetCameraPreview() {
  const [windowOpen, setWindowOpen] = useState(true);
  const setCam = useSelector(selectSelectedElement) as ISetCamera;

  const window = (
    <PiAnimatedPopupWindowPreview
      open={!setCam.isSelecting}
      onCancel={() => setWindowOpen(!windowOpen)}
      title={<PiPopupWindowStaticTitle title="Set Camera" />}
    >
      <div className="w-1/2">
        <CameraPickerField setCamera={setCam} />
      </div>
    </PiAnimatedPopupWindowPreview>
  );

  return <>{window}</>;
}
