import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CameraPosition } from "../../app/slices/EnvironmentSlice";
import {
  selectSelectedElement,
  selectSelectedElementType,
  selectSelectedTake3D,
  updateSelectedInteraction,
} from "../../app/slices/TrainingSlice";
import InteractionPointPreview, {
  CameraPointStrategy,
  SelectedCameraPointStrategy,
} from "./InteractionPointPreview";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";
import { useMapEvents } from "react-leaflet";
import { ISetCamera } from "../../models/interactions/SetCamera";

export default function Viewport3DSetCameraLayer(props: Viewport3DLayerProps) {
  const dispatch = useDispatch();
  const { currentTakeLocation, isCameraSelected } = { ...props };
  const currentTake = useSelector(selectSelectedTake3D);
  const selectedElementType = useSelector(selectSelectedElementType);
  const setCam = useSelector(selectSelectedElement) as ISetCamera;
  const currentCamId = setCam.cameraId;

  const map = useMapEvents({
    click: (e) => {
      if (setCam.isSelecting)
        dispatch(
          updateSelectedInteraction({
            ...setCam,
            isSelecting: false,
          } as ISetCamera)
        );
    },
  });

  const onCamPointClick = (cam: CameraPosition) => {
    // if (!currentTake) return;
    if (cam.id === currentCamId) return;

    const updatedInteraction: ISetCamera = {
      ...setCam,
      cameraId: cam.id,
      takeLocationId: currentTakeLocation!.id,
    };
    dispatch(updateSelectedInteraction(updatedInteraction));
  };

  const onSelectedCamClick = () => {
    dispatch(
      updateSelectedInteraction({ ...setCam, isSelecting: true } as ISetCamera)
    );
  };

  const cams = useMemo(() => {
    return currentTakeLocation?.camera_positions.map((cam, index) => {
      return (
        <InteractionPointPreview
          strategy={
            currentCamId === cam.id
              ? SelectedCameraPointStrategy
              : CameraPointStrategy
          }
          x={cam.position.x}
          y={cam.position.y}
          key={index}
          onClick={() => onCamPointClick(cam)}
        />
      );
    });
  }, [currentTake, setCam.id]);

  const selectedCam = useMemo(() => {
    if (!currentTake) return <></>;

    const cid = setCam.cameraId;

    const camPos = currentTakeLocation?.camera_positions.find(
      (cam) => cam.id === cid
    );

    if (camPos)
      return (
        <InteractionPointPreview
          key={camPos.id}
          strategy={SelectedCameraPointStrategy}
          x={camPos.position.x}
          y={camPos.position.y}
          onClick={onSelectedCamClick}
        />
      );
    else return <></>;
  }, [setCam, setCam.id]);

  return (
    <>
      {setCam.isSelecting && cams}
      {setCam.cameraId && selectedCam}
    </>
  );
}
