import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentEnvironmentId,
  selectCurrentEnvironmentThumbnail,
} from "../../app/slices/EnvironmentSlice";
import {
  selectIsPlaying,
  selectSelectedElement,
  selectSelectedElementType,
  selectSelectedTake3D,
  setImageBounds,
  setViewportBounds,
} from "../../app/slices/TrainingSlice";
import ViewportMap3D from "../leaflet/ViewportMap3D";
import { ImageBounds } from "../training_preview/TrainingBubbleViewport";
import TrainingPreviewOverlay from "../training_preview/TrainingPreviewOverlay";
import CameraWidget from "./CameraWidget";
import "./Viewport3D.css";
import ViewportTools from "../viewport_tools/ViewportTools";

export default function Viewport3D() {
  const url = useSelector(selectCurrentEnvironmentThumbnail);

  const dispatch = useDispatch();
  const imgRef = useRef<HTMLImageElement>(null);
  const isPlaying = useSelector(selectIsPlaying);
  const selectedElement = useSelector(selectSelectedElement);
  const selectedElementType = useSelector(selectSelectedElementType);
  const currentEnvironmentId = useSelector(selectCurrentEnvironmentId);

  const selectedTake = useSelector(selectSelectedTake3D);
  const isCameraSelected = useMemo(() => {
    return selectedElement?.id === selectedTake?.mainCamera.id;
  }, [selectedElement, selectedTake]);

  const onLoad = useCallback(async () => {
    // get actual image size
    if (imgRef.current) {
      let img = new Image();
      img.src = url === undefined ? "" : url!;

      img.onload = async () => {
        // black bars top/bottom
        var imgBounds: ImageBounds = { x: 0, y: 0, width: 0, height: 0 };
        var b = imgRef.current?.getBoundingClientRect();
        var arImage = 0;
        if (b) {
          if (
            (img.width > img.height &&
              img.width / img.height > b.width / b.height) ||
            (img.width <= img.height && b.height > b.width)
          ) {
            imgBounds.width = b.width;
            imgBounds.x = 0;

            //calculate height accordingly
            arImage = img.width / img.height;
            imgBounds.height = b.width / arImage;
            imgBounds.y = (b.height - imgBounds.height) * 0.5;
          } else if (
            img.width > img.height &&
            img.width / img.height <= b.width / b.height
          ) {
            imgBounds.height = b.height;
            imgBounds.y = 0;

            //calculate width accordingly
            arImage = img.width / img.height;
            imgBounds.width = b.height * arImage;
            imgBounds.x = (b.width - imgBounds.width) * 0.5;
          } else {
            if (b.height < b.width) {
              imgBounds.height = b.height;
              imgBounds.y = 0;

              //calculate width accordingly
              arImage = img.width / img.height;
              imgBounds.width = b.height / arImage;
              imgBounds.x = (b.width - imgBounds.width) * 0.5;
            }
          }

          dispatch(
            setViewportBounds({
              x: b.x,
              y: b.y,
              width: b.width,
              height: b.height,
            })
          );
          dispatch(setImageBounds(imgBounds));
        }
      };
    }
  }, [dispatch, setImageBounds, setViewportBounds, imgRef, url]);

  const onWindowResize = useCallback(() => {
    onLoad();
  }, [onLoad]);

  // resize observer
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === imgRef.current) {
          onLoad();
        }
      }
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [onLoad]);

  useEffect(() => {
    // window resize
    window.addEventListener("resize", onWindowResize);

    return () => window.removeEventListener("resize", onWindowResize);
  });

  // resize after stop playing -> the transition messes up the calculations
  useEffect(() => {
    if (!isPlaying) {
      const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
      const waitResize = async () => {
        await delay(150);
        onWindowResize();
      };

      waitResize();
    }
  }, [isPlaying, onWindowResize]);

  return (
    <div className={"viewport3d-root relative"}>
      {selectedElement &&
        selectedElementType !== "actor" &&
        !isCameraSelected && <TrainingPreviewOverlay />}
      <ViewportMap3D />
      <CameraWidget />
      <ViewportTools />
    </div>
  );
}
