import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import InspectPropPreviewBody from "./InspectPropPreviewBody";

export default function InspectPropPreview() {
  const title = <PiPopupWindowStaticTitle title="Inspect Prop" />;

  return (
    <PiPopupWindowPreview title={title} body={<InspectPropPreviewBody />} />
  );
}
