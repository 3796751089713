import { InteractionProperty } from "../InteractionProperty";

export enum InteractionType {
  Default = "default",
  TeleportMarker = "teleportMarker",
  Marker = "marker",
  LisaMessage = "lisaMessage",
  MessagePopup = "messagePopup",
  ImagePopup = "imagePopup",
  VideoPopup = "videoPopup",
  PdfPopup = "pdfPopup",
  MultipleChoiceQuiz = "multipleChoiceQuiz",
  MultiselectQuiz = "multiselectQuiz",
  MultiSelectGraphicsQuiz = "multiSelectGraphicsQuiz",
  MultipleChoiceGraphicsQuiz = "multipleChoiceGraphicsQuiz",
  OrderQuiz = "orderQuiz",
  OrderGraphicsQuiz = "orderGraphicsQuiz",
  ConnectQuiz = "connectQuiz",
  ConnectGraphicsQuiz = "connectGraphicsQuiz",
  ScrabbleQuiz = "scrabbleQuiz",
  WordSpinnerQuiz = "wordSpinnerQuiz",
  ClickRegion = "clickRegion",
  ClickRegionOrderQuiz = "clickRegionOrderQuiz",
  ClickRegionMultiSelectQuiz = "clickRegionMultiSelectQuiz",
  WordQuiz = "wordQuiz",
  PhotoRangeQuiz = "photoRangeQuiz",
  AmbientAudio = "ambientAudio",
  AudioPopup = "audioPopup",
  Conversation = "conversation",
  SetCamera = "setCamera",
  MessageBubble = "messageBubble",
  EmotionBubble = "emotionBubble",
  DialogQuiz = "dialogQuiz",
  Grab = "grab",
  DynamicInteraction = "dynamicInteraction",
  Detach = "detach",
  Attach = "attach",
  Transition = "transition",
  Slideshow = "slideshow",
  ButtonsQuiz = "buttonsQuiz",
  Lookat = "lookat",
  SetPropState = "setPropState",
  ShowUIProp = "showUIProp",
  HideUIProp = "hideUIProp",
  ImageButtonQuiz = "imageButtonQuiz",
  ClickIndicator = "clickIndicator",
  StatementQuiz = "statementQuiz",
  InspectProp = "inspectProp",
}

export default interface Interaction {
  id: string;
  name: string;
  type: InteractionType;
  properties?: InteractionProperty[];
}

export interface Marker extends Interaction {
  xPos: number;
  yPos: number;
}
