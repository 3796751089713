import { CameraAltOutlined } from "@mui/icons-material";
import PiWidget from "../../components/pi_widget/PiWidget";
import PiWidgetToggleButton from "../../components/pi_widget/PiWidgetToggleButton";
import { useSelector } from "react-redux";
import {
  SelectedTab,
  selectSelectedTab,
  selectShowCameraWidget,
  setShowCameraWidget,
} from "../../app/slices/GlobalSlice";
import { useDispatch } from "react-redux";
import {
  selectSelectedElement,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";

export default function ViewportTools() {
  const checked = useSelector(selectShowCameraWidget);
  const dispatch = useDispatch();
  const selectedTake = useSelector(selectSelectedTake3D);
  const selectedElement = useSelector(selectSelectedElement);
  const cameraId = selectedTake?.mainCamera.cameraId;
  const hasCamId = cameraId !== "";
  const timelineSelected =
    useSelector(selectSelectedTab) === SelectedTab.Timeline;

  const visible =
    selectedElement === undefined &&
    selectedTake !== undefined &&
    timelineSelected &&
    hasCamId;

  const widget = (
    <PiWidget>
      <PiWidgetToggleButton
        checked={checked}
        icon={<CameraAltOutlined />}
        onClick={() => dispatch(setShowCameraWidget(!checked))}
      />
    </PiWidget>
  );

  return <>{visible && widget}</>;
}
