import { DragHandleOutlined, PhotoCameraOutlined } from "@mui/icons-material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import {
  selectCameraPreview,
  selectCurrentEnvironmentId,
} from "../../app/slices/EnvironmentSlice";
import {
  selectSelectedElement,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import { RootState } from "../../app/Store";
import { useCameraPreview } from "../../hooks/GetCameraPreviewHook";
import CameraPreviewSkeleton from "./CameraPreviewSkeleton";
import {
  SelectedTab,
  selectSelectedTab,
  selectShowCameraWidget,
} from "../../app/slices/GlobalSlice";

export default function CameraWidget() {
  const selectedEnvironment = useSelector(selectCurrentEnvironmentId);
  const selectedTake = useSelector(selectSelectedTake3D);
  const cameraId = selectedTake?.mainCamera.cameraId;
  const showCameraWidget = useSelector(selectShowCameraWidget);
  const selectedElement = useSelector(selectSelectedElement);

  // onyl render if timeline is selected
  // only render when cameraId is not null
  // only render when valid take is selected
  const timelineSelected =
    useSelector(selectSelectedTab) === SelectedTab.Timeline;
  const hasCamId = cameraId !== "";
  const isVisible =
    selectedElement === undefined &&
    showCameraWidget &&
    selectedTake !== undefined &&
    timelineSelected &&
    hasCamId;

  //Ratio 16:9
  const startSize: [number, number] = [300, 169];
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: startSize[0],
    height: startSize[1],
  });

  //Start Position
  const startPos: [number, number] = [30, 80];
  const [position, setPosition] = useState<{ xPos: number; yPos: number }>({
    xPos: startPos[0],
    yPos: startPos[1],
  });

  const [getCameraPreview] = useCameraPreview(cameraId ?? "");
  const cachedPreview = useSelector((state: RootState) =>
    selectCameraPreview(state, cameraId ?? "")
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (cameraId && cachedPreview === undefined) {
      getCameraPreview(selectedEnvironment);
      setIsLoading(true);
    }
  }, [cameraId]);

  const view = (
    <>
      {/* Header */}
      {isLoading ? (
        ""
      ) : (
        <div className=" min-h-[32px] box-content bg-white flex items-center border-b-[2px] border-b-creator-text-faded ">
          <div className="flex w-full text-creator-primary px-[10px] gap-[10px] text-[22px]">
            <PhotoCameraOutlined fontSize="inherit" color="inherit" />
            <span className="text-[14px] font-normal text-creator-text-sub select-none">
              {selectedTake?.mainCamera.name}
            </span>
          </div>
        </div>
      )}

      {/* PreviewImage */}
      <div className="flex flex-grow w-full h-full">
        <img
          onLoad={() => setIsLoading(false)}
          aria-label="camera-widget-image"
          className={classNames({
            "w-0 h-0": isLoading,
            "w-full h-full": !isLoading,
            "pointer-events-none select-none": true,
          })}
          src={cachedPreview}
          alt=""
        />
      </div>
    </>
  );

  const widget = (
    <Rnd
      className="z-10"
      size={{ width: size.width, height: size.height }}
      minHeight={startSize[0] * 0.5}
      minWidth={startSize[1] * 0.5}
      position={{ x: position.xPos, y: position.yPos }}
      lockAspectRatio={true}
      resizeHandleComponent={{
        bottomRight: <DragHandleOutlined />,
      }}
      resizeHandleStyles={{
        bottomRight: {
          position: "absolute",
          right: "7px",
          bottom: "3px",
          color: "white",
        },
      }}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setSize({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        });
      }}
      onDragStop={(e, d) => {
        setPosition({ xPos: d.x, yPos: d.y });
      }}
      bounds={"parent"}
    >
      <div
        aria-label="camera-widget"
        className="relative w-full h-full bg-white overflow-hidden border-[2px] rounded-5 border-white hover:cursor-grab"
      >
        {isLoading && <CameraPreviewSkeleton />}
        {view}
      </div>
    </Rnd>
  );

  return <>{isVisible && widget}</>;
}
