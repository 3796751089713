import { Prop } from "../../models/Prop";
import usePropHelper from "../../hooks/PropHelper";
import { useMemo, useState } from "react";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import PiMenuButton from "../menu/PiMenuButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePropPreset,
  selectSelectedPropPresetId,
  setSelectedPropPreset,
} from "../../app/slices/TrainingSlice";
import classNames from "classnames";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import useProjectSafety from "../../hooks/ProjectSafety.hook";
import PiConfirmationDialog from "../../components/PiConfirmationDialog";

export default function PropCard(props: { prop: Prop }) {
  const { prop } = { ...props };
  const { getPropDTO } = usePropHelper();
  const dto = useMemo(() => getPropDTO(prop.propId), [prop.propId]);
  const { t } = useTranslation();
  const selectedPropId = useSelector(selectSelectedPropPresetId);
  const isSelected = useMemo(
    () => selectedPropId === prop.id,
    [selectedPropId, prop.id]
  );
  const dispatch = useDispatch();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const { propHasReferencesInProject } = useProjectSafety();
  const onClick = () => {
    dispatch(setSelectedPropPreset(prop.id));
  };

  const onDelete = () => {
    if (propHasReferencesInProject(prop.id)) setConfirmDialogOpen(true);
    else dispatch(deletePropPreset(prop.id));
  };

  const menu = (
    <div className={"my-auto"}>
      <PiPopoverMenu>
        <PiMenuButton text={t("delete")} onClick={onDelete} />
        <></>
      </PiPopoverMenu>
    </div>
  );

  const image = (
    <img
      className={"w-full h-full object-contain p-[4px]"}
      src={dto.propContent.downloadUri}
      alt={""}
    />
  );
  const noImage = (
    <div className="flex max-w-[32px] max-h-[32px] m-auto text-[16px] ">
      <div className="m-auto">
        <ViewInArOutlinedIcon fontSize="inherit" color="inherit" />
      </div>
    </div>
  );
  const propPreview = dto.propContent.downloadUri ? image : noImage;

  const confirmationDialog = (
    <PiConfirmationDialog
      open={confirmDialogOpen}
      onClose={() => setConfirmDialogOpen(false)}
      text={t("delete_prop.text")}
      title={t("delete_prop.title")}
      onConfirm={() => setConfirmDialogOpen(false)}
      confirmText={t("delete_prop.confirm")}
      cancelText=""
      confirmButtonVariant="piRed"
      height="275px"
      hideCancelButton={true}
    />
  );

  return (
    <div
      className={classNames({
        "w-full h-[60px] flex gap-[10px] p-[10px] border-[2px] border-solid cursor-pointer rounded-[5px] select-none hover:border-creator-primary-hover":
          true,
        "border-creator-primary text-creator-primary font-bold": isSelected,
        "border-creator-border text-creator-text-sub ": !isSelected,
      })}
      onClick={onClick}
    >
      <div
        className={classNames({
          "my-auto flex w-[36px] h-[36px] p-[4px] rounded-full border-solid border-[2px] drop-shadow-lg ":
            true,
          "border-creator-primary": isSelected,
          "border-creator-border": !isSelected,
        })}
      >
        {propPreview}
      </div>
      <div
        className={classNames({
          "text-14 my-auto truncate flex-1": true,
          "font-bold": isSelected,
          "font-medium": !isSelected,
        })}
      >
        {prop.name}
      </div>
      {menu}
      {confirmationDialog}
    </div>
  );
}
