import { motion } from "framer-motion";

export default function PiAnimatedPopupWindowPreview(props: {
  open: boolean;
  children: JSX.Element | JSX.Element[];
  title: JSX.Element;
  onCancel: () => void;
}) {
  const { open, children, title, onCancel } = { ...props };

  const animationVariants = {
    open: { y: 0, x: "-50%" },
    closed: { y: 176, x: "-50%" },
  };

  const body = (
    <motion.div
      className={
        "w-[90%] h-[208px] bg-white absolute bottom-[32px] rounded-[5px] left-[50%] transform translate-x-[-50%] shadow-md p-[24px] flex flex-col gap-[12px]"
      }
      animate={open ? "open" : "closed"}
      initial={animationVariants.open}
      variants={animationVariants}
    >
      <div className="" onClick={onCancel}>
        {title}
      </div>

      {children}
    </motion.div>
  );

  return <>{body}</>;
}
