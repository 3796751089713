import { useDispatch } from "react-redux";
import { ISetCamera } from "../../../models/interactions/SetCamera";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import useMetadataHelper from "../../../hooks/MetadataHelper.hook";
import { useMemo } from "react";
export default function CameraPickerField(props: { setCamera: ISetCamera }) {
  const { setCamera } = { ...props };
  const dispatch = useDispatch();
  const { getCameraPosition } = useMetadataHelper();

  const camPos = useMemo(() => {
    return getCameraPosition(setCamera.cameraId);
  }, [getCameraPosition, setCamera]);

  const setSelectinCam = () =>
    dispatch(
      updateSelectedInteraction({
        ...setCamera,
        isSelecting: true,
      } as ISetCamera)
    );

  const label = (
    <div className={"text-interaction-primary text-base font-bold h-[30px]"}>
      Camera
    </div>
  );

  const noCamSelected = (
    <div className="w-[36px] h-[36px] text-[22px] text-white rounded-full bg-interaction-primary flex justify-center items-center cursor-pointer">
      <CameraAltOutlinedIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const camSelected = (
    <div className="flex items-start justify-start gap-[8px] cursor-pointer w-full">
      <div className="w-[36px] h-[36px] text-[22px] text-white rounded-full bg-interaction-primary flex justify-center items-center cursor-pointer">
        <CameraAltOutlinedIcon fontSize="inherit" color="inherit" />
      </div>
      <div className="text-interaction-primary text-[14px] font-medium my-auto">
        {camPos?.name}
      </div>
    </div>
  );

  const content = setCamera.cameraId === "" ? noCamSelected : camSelected;

  return (
    <div className={"w-full flex flex-col"}>
      <div className={"text-interaction-primary text-base font-bold h-[30px]"}>
        Camera
      </div>
      <div
        className={
          "w-full h-[42px] piXl:h-[68px] piLg:h-[58px] flex bg-interaction-softBackground border-b-2 border-solid border-interaction-primary px-[18px] hover:bg-interaction-primary-washed items-center justify-center"
        }
        onClick={setSelectinCam}
      >
        {content}
      </div>
    </div>
  );
}
