import { useSelector } from "react-redux";
import PropDropdown from "../grab/PropDropdown";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { IInspectProp } from "../../../models/interactions/InspectProp";
import { useDispatch } from "react-redux";
import usePropHelper from "../../../hooks/PropHelper";
import { useMemo } from "react";
import PiInteractionStringDropdown from "../../../components/PiInteractionStringDropdown";

export default function InspectPropPreviewBody() {
  const interaction = useSelector(selectSelectedElement) as IInspectProp;
  const dispatch = useDispatch();
  const { getUsedPropByInstanceId } = usePropHelper();

  const onChangeProp = (propId: string) => {
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        propId: propId,
        tag: "",
      } as IInspectProp)
    );
  };

  const onChangeField = (field: keyof IInspectProp, value: any) => {
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        [field]: value,
      } as IInspectProp)
    );
  };

  const tags = useMemo(() => {
    if (!interaction.propId) return undefined;
    return getUsedPropByInstanceId(
      interaction.propId
    ).metadata.inspectDefinitions.map((id) => id.name);
  }, [getUsedPropByInstanceId, interaction.propId]);

  const propDropdown = (
    <PropDropdown
      label="Prop"
      selectedPropStateId={interaction.propId}
      onClick={(propStateId) => onChangeProp(propStateId)}
    />
  );

  const tagDropdown = (
    <PiInteractionStringDropdown
      label="Tag"
      onClick={(tag) => onChangeField("tag", tag)}
      items={tags}
      selectedItem={
        interaction.tag === "" ? "No tag selected" : interaction.tag
      }
    />
  );

  return (
    <div className="w-full h-full">
      <div className="flex gap-[12px]">
        {propDropdown}
        {tagDropdown}
      </div>
    </div>
  );
}
