import classNames from "classnames";

export default function PiWidgetToggleButton(props: {
  checked: boolean;
  icon: JSX.Element;
  onClick: () => void;
}) {
  const { checked, icon, onClick } = { ...props };

  return (
    <div
      className={classNames({
        "w-[30px] h-[30px] flex items-center justify-center rounded-[5px] cursor-pointer":
          true,
        "bg-creator-primary text-white": checked,
        "bg-transparent text-creator-text-sub": !checked,
      })}
      onClick={onClick}
    >
      {icon}
    </div>
  );
}
