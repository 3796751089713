import { ActorMovementType } from "./ActorMovementType";

export const activitiesMapped = new Map<string, string>([
  ["None", "/dialog_quiz/dialog_quiz_npc.png"],
  ["NormalTalkingLoop", "/dialog_quiz/dialog_quiz_npc.png"],
  ["Thinking", "/dialog_quiz/dialog_quiz_npc.png"],
]);

export const propsMapped = new Map<string, string>([
  ["None", "/actor_props/none.png"],
  ["CoffeeCup", "/actor_props/coffee_cup.png"],
  ["Clipboard", "/actor_props/clipboard.png"],
]);

export const actorActivities: string[] = [
  "None",
  "NormalTalkingLoop",
  "Thinking",
];

export const actorStates: string[] = ["Normal", "Hyperventilating"];

export const actorProps: string[] = ["None", "CoffeeCup", "Clipboard"];

// stores the state of an actor in between takes
export interface ActorState {
  id: string;
  actorId: string;
  positionId: string;
  movement: ActorMovementType;
  lookat: string;
  outfit: string;
  state: string;
  activity: string;
  prop: string;
}
