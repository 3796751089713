import { useSelector } from "react-redux";
import { selectSelectedTake3D } from "../app/slices/TrainingSlice";
import { selectEnvironmentInteractionPoints } from "../app/slices/EnvironmentSlice";
import { useMemo } from "react";

export default function useMetadataHelper() {
  const take = useSelector(selectSelectedTake3D);
  const takePositions = useSelector(selectEnvironmentInteractionPoints);

  const currentTakeLocation = useMemo(() => {
    return take?.interactionPointId
      ? takePositions.find((point) => point.id === take.interactionPointId)
      : undefined;
  }, [take, takePositions]);

  const getCameraPosition = (camId: string) => {
    if (!currentTakeLocation) return undefined;

    return currentTakeLocation.camera_positions.find((cam) => cam.id === camId);
  };

  return { currentTakeLocation, getCameraPosition } as const;
}
